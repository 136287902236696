<template>
    <Aside />
</template>

<script>
import Aside from '../components/Aside.vue'
export default {
    components: {
        Aside
    }
}
</script>